<template>
  <div class="about container">
    <div
      style="height: 150px;margin-bottom: 10px;text-align: center;margin-top: 100px;margin-bottom: 100px;"
    >
      <img
        src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/banner/candiy_transparent.png"
        alt="캔디"
        style="height: 100%;"
      />
    </div>
    <!--    <p><a href="https://candiy.slack.com" target="_blank">Join CANDiY slack</a></p>-->

    <!--    <p>If you don't find answers to your questions about CANDiY, the best way is to use <a href="https://forum.solidproject.org/t/candiy-the-pod-in-the-pocket/3378" target="_blank">Solid Community Forum</a></p>-->
    <!--    <p>If you think CANDiY is what you need, you can share your opinion, impove & <a href="https://github.com/scenaristeur/solid-vue-panes/projects/1?add_cards_query=is%3Aopen" target="_blank">contribute</a> too.</p>-->

    <!--  <h3>Where is the source code of CANDiY?</h3>-->
    <!--    <p>Git repository at <a href="https://github.com/scenaristeur/solid-vue-panes/blob/master/README.md" target="_blank">https://github.com/scenaristeur/solid-vue-panes</a>.</p>-->

    <div v-if="naverUserName != null">
      <b-button
        to="/editor"
        style="background-color: #5EBDEB;outline:none; border:none; width:100%; font-size: 18px; font-weight: 600;;"
        >캔디와 연결하기</b-button
      >
    </div>
    <div v-else>
      <div
        class="solidintro"
        style="font-size: 14px; color: #929292; font-weight: 500;"
      >
        ⚡️ 3초만에 빠른 회원가입
      </div>
      <div
        class="text-center"
        style="width: 95%; margin: auto; text-align: center;max-width: 450px;"
      >
        <a
          v-on:click="naverlogin()"
          href="#"
          style="background: #03C75A;display: block;text-decoration: none;color: #fff;display: flex;height: 45px;border-radius: 6px;overflow: hidden;justify-content: space-between;margin-bottom:10px;"
          ><img
            height="100%"
            src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/btnG_%EC%95%84%EC%9D%B4%EC%BD%98%EC%82%AC%EA%B0%81.png"
            alt="네이버 로그인"
          />
          <span
            style="display: block;width: 90%;font-size: 15px;line-height: 45px;font-weight: 600;"
            >네이버 로그인</span
          >
        </a>
        <a
          href="#"
          style="background: #FEE500;display: block;text-decoration: none;color: #fff;display: flex;height: 45px;border-radius: 6px;overflow: hidden;justify-content: space-between;"
        >
          <span
            style="display: block; height: 100%; aspect-ratio: 1/1; overflow: hidden;"
          >
            <img
              src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/kakao_login_large_wide.png"
              alt="카카오 로그인"
              style="height: 100%;"
          /></span>
          <span
            style="display: block;width: 90%;font-size: 15px;line-height: 45px;font-weight: 600;color: #000; opacity: 85%;"
            >카카오 로그인</span
          >
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  //  store,

  name: "Storage",
  data: function() {
    return {};
  },
  created() {
    // if (this.webId == null) {
    //   this.popupLogin();
    // }
    //  this.solid= window.solid
    //  this.webId =
    //  fc = new SolidFileClient(auth)
    //  example     await solid.data.from(this.fileUrl)[index]['http://www.w3.org/2005/01/wf/flow#message'].set(namedNode(messUri))
  },
  methods: {
    naverlogin() {
      localStorage.removeItem("naver_access_token");

      this.client_id = process.env.VUE_APP_NAVER_CLIENT_ID;
      this.callbackUrl = process.env.VUE_APP_NAVER_CALLBACK_URL;
      this.state = Math.random()
        .toString(36)
        .substring(2, 16);
      var url =
        "https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=" +
        this.client_id +
        "&redirect_uri=" +
        this.callbackUrl +
        "&state=" +
        this.state;
      window.location.replace(url);
    },
  },
  computed: {
    naverUserName() {
      return this.$store.state.naver.naverUserName;
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
p {
  margin: 0;
  padding: 0;
}
.about {
  text-align: left;
  margin-top: 10px;
  width: 100%;
  padding: 0 25px;
  margin-bottom: 50px;
}
.title-box {
  margin: 0 auto;
  height: 6.9444vw;
  position: relative;
  width: 100%;
}
.title-box a {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 4.7222vw;
  color: #007bff;
  font-weight: 400;
}
.title-box a svg {
}
.page-title {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin: 30px 0 15px;
}
.lead {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
  color: #666;
  word-break: keep-all;
  margin-bottom: 40px;
}
.content {
  margin: auto;
}
.content .cont-body {
  margin-bottom: 30px;
  background: #fff;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 10px;
}
.content .cont-body h3 {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}
.content .cont-body p {
  font-size: 16px;
  font-weight: 400;
  color: #666;
  word-break: keep-all;
}
.content .cont-body ul {
  padding-inline-start: 0;
  list-style: none;
  margin: 0;
}
.content .cont-body ul li {
  font-size: 16px;
  color: #666;
  font-weight: 400;
  word-break: keep-all;
  margin-bottom: 8px;
  padding-top: 5px;
}
.content .cont-body ul li:last-child {
  margin-bottom: 0;
}
.content .cont-body.first {
}
.content .first h3 + p {
  margin-bottom: 10px;
}
.content .cont-body .content-img {
  width: 100%;
  height: 230px;
  text-align: center;
  margin-bottom: 30px;
}
.content .cont-body .content-img img {
  height: 100%;
}
.content .cont-body.second {
}
.content .second h3 + p {
  margin-bottom: 10px;
}
.content .cont-body.second ul li {
  text-align: center;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
}
.content .cont-body.second ul li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.content .cont-body.second ul li p {
  font-weight: 900;
  color: #5ebdeb;
  font-size: 20px;
  text-align: center;
  margin-bottom: 5px;
}
.content .cont-body.third {
}
.content .cont-body.third h3 {
  margin-bottom: 20px;
}
.content .cont-body.third ul li p {
  font-weight: 900;
  color: #fb5c5c;
  font-size: 18px;
  margin-bottom: 5px;
}
.solidintro {
  margin-bottom: 10px;
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  word-break: keep-all;
}
</style>
